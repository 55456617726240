<template>
  <div id="QualityAssessment">
    <CSBreadcrumb/>
    <div class="filter-panel" style="padding: 15px 30px">
      <input type="text" placeholder="搜索考核项" v-model="assessmentItemsDialog.keyword" style="height: 30px; vertical-align: middle; margin-right: 20px; border-radius: 3px; border: 1px solid #999;">
      <button class="btn btn-primary" @click="assessmentItemsDialog.searchItems" style="margin-bottom: 0;">查询</button>
    </div>
    <div class="result-panel">
        <CSTable :thead-top="60">
            <template v-slot:thead>
                <tr>
                    <th>考核项</th>
                    <th>{{
                            state == 1 ? '扣分' : '分值'
                        }}</th>
                    <th style="min-width: 140px;">操作</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <template v-if="assessmentItemsDialog.items.length > 0">
                    <tr v-for="item in assessmentItemsDialog.items"
                        :key="item.id">
                        <td>{{item.name || '-'}}</td>
                        <td>{{item.score || '-'}}</td>
                        <td>
                            <span class="allow-click" style="margin-right: 10px;" @click="openEditAssessmentItem(item)">修改</span>
                            <span class="allow-click"
                                  @click="deleteAssessmentItem(item.id)">删除</span>
                        </td>
                    </tr>
                </template>
            </template>
        </CSTable>
    </div>
    <!--  修改考核项  -->
    <CSDialog :dialog-width="916" :dialog-visible="editQualityAssessmentItemDialog.visible"
      @onClose="editQualityAssessmentItemDialog.onClose" @onConfirm="editQualityAssessmentItemDialog.onConfirm"
      dialog-title="修改考核项"
    >
      <template v-slot:dialog-content>
        <div class="dialog-form edit-assessment-dialog">
          <div class="dialog-form-item">
            <div class="dialog-form-item-label top">考核项</div>
            <div>
              <textarea placeholder="请输入" v-model="editQualityAssessmentItemDialog.data.name"></textarea>
            </div>
          </div>
          <div class="dialog-form-item">
            <div class="dialog-form-item-label">扣分分数</div>
            <div>
              <input type="number" placeholder="请输入" v-model="editQualityAssessmentItemDialog.data.score">
            </div>
          </div>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSDialog from "@/components/common/CSDialog";

import {
  queryQualityAssessmentItemsUrl,
  updateQualityAssessmentItemUrl,
  deleteQualityAssessmentItemUrl
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
  name: "QualityAssessment",
  props: {
    detailId: Number,
    state: Number,
  },
  components: {
      CSTable,
    CSBreadcrumb,
    CSDialog,
  },
  data() {
    return {
      assessmentItemsDialog: {
        visible: false,
        items: [],
        keyword: '',
        searchItems: () => {}
      },
      editQualityAssessmentItemDialog: {
        visible: false,
        data: {
          name: "",
          score: '',
        },
        onConfirm() {},
        onClose: () => {
          this.editQualityAssessmentItemDialog.visible = false;
        }
      },
    }
  },
  created() {
    this.openAssessmentItemsDialog(this.detailId);
  },
  methods: {
    async openAssessmentItemsDialog(detailId) {
      this.assessmentItemsDialog.keyword = '';
      this.assessmentItemsDialog.items = await this.getQualityAssessmentItems(detailId, this.assessmentItemsDialog.keyword);
      this.assessmentItemsDialog.searchItems = async () => {
      this.assessmentItemsDialog.items = await this.getQualityAssessmentItems(detailId, this.assessmentItemsDialog.keyword);
      }
    },
    getQualityAssessmentItems(detailId, search) {
      return this.$fly.post(queryQualityAssessmentItemsUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        detailId, search
      })
      .then(res => {
        if (res.code !== 0) {
          return ;
        }
        return res.data;
      })
    },
    openEditAssessmentItem(item) {
      this.editQualityAssessmentItemDialog.data = {
        name: item.name,
        score: item.score,
      };
      this.editQualityAssessmentItemDialog.visible = true;
      this.editQualityAssessmentItemDialog.onConfirm = () => {
        this.$fly.post(updateQualityAssessmentItemUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          id: item.id,
          ...this.editQualityAssessmentItemDialog.data
        }, {
          headers: {
            isForm: true,
          }
        })
        .then(res => {
          if (res.code !== 0) {
            return ;
          }
          this.$vc.toast('修改成功');
          this.editQualityAssessmentItemDialog.onClose();
          this.assessmentItemsDialog.searchItems();
        })
      }
    },
    deleteAssessmentItem(id) {
      this.$CSDialog.confirm({
        title: '提示',
        message: '确定删除吗?',
        onConfirm: () => {
          this.$fly.get(deleteQualityAssessmentItemUrl, {
            id
          })
          .then(res => {
            if (res.code !== 0) {
              return ;
            }
            this.$vc.toast('删除成功');
            this.$CSDialog.instance.closeDialog();
            this.assessmentItemsDialog.searchItems();
          })
        }
      })
    },
  }
}
</script>

<style lang="stylus" scoped>
.dialog-form
  padding 27px 30px
  font-size 24px
  color #000
  &-item
    &:not(:last-of-type)
      margin-bottom 23px
    &-label
      width 96px
      text-align right
      margin-right 40px
      display inline-block
      vertical-align middle
    & > div
      display inline-block
      vertical-align middle
      .inspector
        margin-bottom 0
        display inline-block
        vertical-align middle
        &:not(:last-of-type)
          margin-bottom 17px
        .icon
          margin-left 10px
      .cs-select
        width 250px
      input
        &::placeholder
          color #999
        width 250px
        height 40px
        border-radius 4px
        padding 0 5px
        border 1px solid #979797
.dialog-form
    &-item
      &-label
        &.top
          vertical-align top
      & > div
        textarea
          width 720px
          height 200px
          background #F0F0F0
          border-radius 10px
          padding 10px
          resize none
          border none
          outline none
        input
          width 250px
          height 40px
          border-radius 4px
          padding 10px
          box-sizing border-box
          border 1px solid #979797
</style>
